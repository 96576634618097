<template>
  <div>
    <div class="box">
      <div>
        <h2 class="tile">Pase Médico</h2>
      </div>
      <div class="columns mt-6 is-multiline">
        <div class="column is-4" v-for="(file, index) in files" :key="index">
          <div class="card">
            <div class="card-content">
              <p class="title">
                {{file}}
              </p>
            </div>
            <footer class="card-footer">
              <a @click="handleDownload(file)" class="card-footer-item button is-primary">Descargar</a>
              <a @click="handleUpdate(file)" class="card-footer-item button is-default">
                Actualizar
              </a>
            </footer>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showModal">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Actualizar {{selectedFile}}</p>
          <button
              type="button"
              class="delete"
              @click="showModal = false"/>
        </header>
        <section class="modal-card-body">
          <div class="content">
            <form @submit.prevent="onSubmit" id="pase-form" enctype="multipart/form-data">
              <b-field class="file">
                <b-upload v-model="file" expanded accept=".jsp" required name="file">
                  <a class="button is-primary is-fullwidth">
                    <b-icon icon="upload"></b-icon>
                    <span>{{ file.name || "Clic para seleccionar archivo" }}</span>
                  </a>
                </b-upload>
              </b-field>
            </form>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button native-type="submit" form="pase-form" type="is-primary" @click="onSubmit">Actualizar</b-button>
          <b-button native-type="button" @click="showModal = false">Cerrar</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "PaseMedicoFiles",
  data(){
    return {
      files: Array,
      selectedFile: null,
      showModal: false,
      file: {}
    }
  },
  mounted() {
    this.prepareComponent();
  },
  methods: {
    prepareComponent() {
      this.getData();
    },
    getData(){
      this.$loader.show();
      this.$http.get('/external/payara/pase-medico-files').then(({data}) => {
        this.files = data.data;
      }).finally(() => {
        this.$loader.hide();
      })
    },
    onSubmit(e){
      const formData = new FormData(e.target);
      formData.append('filename', this.selectedFile);
      this.$loader.show();
      this.$http.post('/external/payara/pase-medico-files',formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        this.showModal = false;
        this.file = {};
        this.selectedFile = null;
        this.$buefy.dialog.alert({
          title: 'Procesado con éxito',
          type: 'is-success',
          hasIcon: true,
          icon: '',
          message: 'Documento almacenado con éxito'
        });
      }).finally(() => {
        this.$loader.hide();
      })
    },
    handleDownload(file) {
      this.$loader.show();
      this.$http.get('/external/payara/pase-medico-files/'+file).then(({data}) => {
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        const blob = new Blob([data], {type: "octet/stream"});
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = file;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }).finally(() => {
        this.$loader.hide();
      })
    },
    handleUpdate(file) {
      this.selectedFile = file;
      this.showModal = true;
    }
  }
}
</script>

<style scoped>

</style>